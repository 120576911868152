import {
  container,
  dangerColor,
  description,
  primaryColor,
  section,
  textCenter,
  title,
} from "assets/jss/material-kit-pro-react.jsx"

const hotPepperEatingContestAboutStyle = (theme) => ({
  title: {
    ...title,
    ...textCenter,
    marginTop: "0",
  },
  description: {
    ...description,
    ...textCenter,
  },
  documentIcons: {
    margin: "20px auto 0 auto",
    fontSize: "1.5rem",
    color: primaryColor[0],
  },
  documentsItem: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    margin: "1rem 0rem",
  },
  aboutButton: {
    margin: "1rem",
    lineHeight: "18px",
    "& svg": {
      marginLeft: "5px",
    },
  },
  hotPepperEatingContestAboutButtons: {
    ...textCenter,
  },
  hotPepperEatingContestInfoArea: {
    marginBottom: "40px",
  },
  hotPepperEatingContestInfoAreaMiddle: {
    marginBottom: "70px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
    },
  },
  closed: {
    ...description,
    ...textCenter,
    color: dangerColor[0],
  },
  container,
  customIconWrapper: {
    float: "left",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "30px auto -35px auto",
      textAlign: "center",
    },
  },
  contestsImage: {
    margin: "0 auto",
    borderRadius: "6px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  },
  contestsSponsorImage: {
    height: "150px",
  },
  contestsImageItem: {
    [theme.breakpoints.down("sm")]: {
      order: "1",
    },
  },
  contestsTextItem: {
    [theme.breakpoints.down("sm")]: {
      order: "2",
    },
  },
  contestsTextSvgItem: {
    [theme.breakpoints.down("sm")]: {
      order: "2",
      display: "flex",
      flexDirection: "column",
    },
  },
  icon: {
    width: "2.5rem !important",
    height: "2.5rem",
    fontSize: "2.5rem",
    color: "#f24231",
    transform: "scale(.7712)",
  },
  subtitle: {
    ...title,
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "0px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem",
    },
  },
  section: {
    ...section,
    padding: "80px 0px",
  },
})

export default hotPepperEatingContestAboutStyle
