// @fortawesome icons
import { faArrowAltRight, faHeat } from "@fortawesome/pro-solid-svg-icons"
import {
  faCalendarDay,
  faSearchLocation,
  faUsdSquare,
} from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page images
// page styles
import hotPepperEatingContestAboutStyle from "assets/jss/material-kit-pro-react/views/hotPepperEatingContestPageSections/hotPepperEatingContestAboutStyle.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import InfoArea from "components/InfoArea/InfoArea.jsx"
// gatsby libraries
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(hotPepperEatingContestAboutStyle)

export default function HotPepperEatingContestAbout() {
  const {
    hotPepperEatingContestAbout1,
    hotPepperEatingContestSponsor,
  } = useStaticQuery(graphql`
    query getHotPepperEatingContestAboutImages {
      hotPepperEatingContestAbout1: file(
        relativePath: {
          eq: "hot-pepper-eating-contest/hot-pepper-eating-contest-1.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      hotPepperEatingContestSponsor: file(
        relativePath: {
          eq: "hot-pepper-eating-contest/hot-pepper-eating-contest-sponsor.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer
          justifyContent="center"
          className={classes.hotPepperEatingContestInfoArea}
        >
          <GridItem xs={12} sm={10} md={8}>
            <h2 className={classes.title}>
              Get ready to climb the Scoville Scale!
            </h2>
            <GatsbyImage
              image={
                hotPepperEatingContestSponsor.childImageSharp.gatsbyImageData
              }
              imgStyle={{
                objectFit: "contain",
              }}
              className={classes.contestsSponsorImage}
              alt="Hot Pepper Eating Contest Sponsor"
            />
          </GridItem>
        </GridContainer>
        <GridContainer
          justifyContent="center"
          alignItems="center"
          className={classes.hotPepperEatingContestInfoAreaMiddle}
        >
          <GridItem md={5} sm={8} xs={10} className={classes.contestsImageItem}>
            <GatsbyImage
              image={
                hotPepperEatingContestAbout1.childImageSharp.gatsbyImageData
              }
              imgStyle={{
                objectFit: "contain",
              }}
              className={classes.contestsImage}
              alt="Hot Pepper Eating Contest About"
            />
          </GridItem>
          <GridItem xs={12} sm={10} md={7} className={classes.contestsTextItem}>
            <InfoArea
              className={classes.infoArea}
              title="Hot Pepper Eating Contest"
              description={
                <p>
                  Ladies and gentlemen, get ready to feel the burn! We are
                  excited to announce our annual hot pepper eating contest,
                  "Scoville Showdown". This event will test the limits and spice
                  tolerance of some of the most daring heat-seekers.
                  <br />
                  <br />
                  With an array of fiery peppers available to sample, 10
                  contestants will have to endure scorching levels of heat in
                  pursuit of the ultimate prize. We will also be looking for 5
                  alternates in case there are any contestants who are unable to
                  participate. Whether you are a competitive eater or simply
                  enjoy the thrill of the heat, Fire Mania is an event not to be
                  missed.
                  <br />
                  <br />
                  Thank you to Ponics Produce of Edgerton, WI for the HOT
                  PEPPERS and to the Wisconsin Greenhouse Company for this spicy
                  event.
                  <br />
                  <br />
                  <b>
                    See below for registration, rules and a release for the
                    heat.
                  </b>
                </p>
              }
              faIcon={faHeat}
              iconColor="secondary"
            />
          </GridItem>
        </GridContainer>
        <GridContainer alignItems="center" justifyContent="center">
          <h4 className={classes.subtitle}>Contest Details</h4>
          <GridItem md={4} sm={12} className={classes.documentsItem}>
            <FontAwesomeIcon
              className={classes.documentIcons}
              icon={faCalendarDay}
            />
            <h5 className={classes.description}>
              <b>When</b>
            </h5>
            <p className={classes.description}>5:20PM Sept. 7</p>
          </GridItem>
          <GridItem md={4} sm={12} className={classes.documentsItem}>
            <FontAwesomeIcon
              className={classes.documentIcons}
              icon={faSearchLocation}
            />
            <h5 className={classes.description}>
              <b>Where</b>
            </h5>
            <p className={classes.description}>Chilimania Grounds</p>
          </GridItem>
          <GridItem md={4} sm={12} className={classes.documentsItem}>
            <FontAwesomeIcon
              className={classes.documentIcons}
              icon={faUsdSquare}
            />
            <h5 className={classes.description}>
              <b>Price</b>
            </h5>
            <p className={classes.description}>FREE!</p>
          </GridItem>
        </GridContainer>
        <GridContainer
          justifyContent="center"
          className={classes.hotPepperEatingContestAboutButtons}
        >
          <GridItem xs={12}>
            {/* <p className={classes.closed}>
              <b>*Please Note:</b> On-line registration for Scoville Showdown is
              now <b>CLOSED</b>.
            </p> */}
            <Link to="/scoville-showdown-registration">
              <Button className={classes.aboutButton} color="primary" icon>
                Register
                <FontAwesomeIcon icon={faArrowAltRight} />
              </Button>
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
